import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index.vue";

import privacyPolicy from "../views/privacyPolicy/index.vue";
import userAgreement from "../views/userAgreement/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: Index,
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: privacyPolicy,
  },
  {
    path: "/user-agreement",
    name: "userAgreement",
    component: userAgreement,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
