<template>
  <div>
    <MobelHome v-if="isMobile()"></MobelHome>
    <Home v-else></Home>
  </div>
</template>
<script>
import Home from "../views/home/index.vue";
import MobelHome from "../views/m-home/index.vue";
export default {
  name: "",
  components: {
    Home,
    MobelHome,
  },
  data() {
    return {
      constants: {},
    };
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
};
</script>
