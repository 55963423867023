<template>
  <div class="page flex-col">
    <div class="section_6 flex-row">
      <div class="box_8 flex-col">
        <div class="block_2 flex-col" id="aboutUs">
          <span class="text_1">十年沉淀领先产品与优秀口碑</span>
          <div class="box_1 flex-col">
            <div class="group_7 flex-row justify-between">
              <div class="block_3 flex-col"></div>
              <div class="section_7 flex-col">
                <span class="text_2">西拓行远科技有限公司</span>
                <img
                  class="image_1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng411095bab6b2ad134add7201553e3b5cb6685fee1bb1e5ef33753aa60c281978.png"
                />
                <img
                  class="image_2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng5c9459cb8d3b17531aef9d8a224d6115da4657f181607d0490c2a71fc16fa06e.png"
                />
                <span class="paragraph_1">
                  “西拓行远科技有限公司”成立于2014年，总部位于北京，致力于为不同行业的企业客户提供解决方案级别的车用能源SaaS工具及运营服务，帮助客户实现“流程步步清楚、管理心中有数”的管理数字化目标。
                  <br />
                  <br />
                  公司核心成员来自互联网、出行、能源等行业头部企业高管，拥有领先的产品技术及扎实的运营服务能力，曾为京东、顺丰、中通、极兔、满帮、国家电网、南方电网等知名客户提供一站式服务。
                </span>
              </div>
            </div>
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng1ed43343fe8e2f0ea4dcee777c8e9824ce245324bb6397d704b70e64c59a01f2.png"
            />
            <div class="image-wrapper_3 flex-row">
              <img
                class="image_4"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngb28603d0c86d4bb76406d5974f6d82457fa3b9b54c6d714732e80315b06702b2.png"
              />
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng96cd4d7ceb5d499c020eaa1859a1e0ce26d3b96a5b08e2f8a28671a49b806fc9.png"
              />
              <img
                class="label_1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8e588621376a418f9535caeac28155731b9f106a4b4a823242fb776dbf7fa1ef.png"
              />
              <img
                class="image_6"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng9be2927dc16d95195be04a867fe7bf8d479a8ce7cb5bc73324b442614b698241.png"
              />
              <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng739ee91c6bb08a760dae6db49e86676bdd5e0743572b74a295d1ea454f16706b.png"
              />
              <img
                class="image_7"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngf0d9109338cccd090b7004c5a7942075ca2d7336e939790455b29dc1d9dce3d2.png"
              />
              <img
                class="image_8"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng7ce7ff5e46120e4f2e75958fc954c9904f4f0f2f12ca1dcf367a16389ac719a9.png"
              />
            </div>
          </div>
        </div>
        <div class="block_5 flex-col" id="product">
          <div class="text-wrapper_6 flex-row">
            <span class="text_3">产品只是基础、服务体现差异</span>
          </div>
          <div class="box_9 flex-row justify-between">
            <div class="group_2 flex-col"></div>
            <img
              class="image_9"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngdd144b2aec6abc1bfcec7167664d9520ef4948948369c90790df12d7727565b9.png"
            />
          </div>
          <div class="group_3 flex-col">
            <div class="box_10 flex-row">
              <div class="block_6 flex-col"></div>
              <div class="block_7 flex-col"></div>
              <div class="block_8 flex-col"></div>
            </div>
            <div class="section_3 flex-col"></div>
          </div>
        </div>
      </div>
      <div class="block_9 flex-col">
        <div class="text-wrapper_7 flex-row justify-between">
          <span class="text_4">联系我们</span>
          <span class="text_5">关注我们</span>
        </div>
        <div class="box_11 flex-row">
          <img
            style="cursor: pointer;"
            @click="jumpClick('app')"
            class="image_10"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng91e2da86813fb7ad4d7da6bdee7dbbe19e179d8addb5e82ef16010d2b982568d.png"
          />
          <div class="block_14 flex-col justify-between">
            <div class="text-group_8 flex-col justify-between">
              <span class="text_6">公司邮箱：</span>
              <span class="text_7">wuxiaoli&#64;westtxy.com</span>
            </div>
            <div class="text-group_9 flex-col justify-between">
              <span class="text_8">联系电话：</span>
              <span class="text_9">4006780037</span>
            </div>
          </div>
          <div class="group_5 flex-col">
            <div class="section_4 flex-col"></div>
          </div>
        </div>
        <div class="text-group_10 flex-col justify-between">
          <span class="text_10">公司地址</span>
          <span class="text_11">北京市通州区新华北路55号2幢四层8-35室</span>
        </div>
        <img
          class="image_11"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng23dc308230ac8e70507a6d68d863779e6af0a2e56c707da82182a1224ad84feb.png"
        />
        <div class="box_12 flex-row">
          <span class="text_12">
            版权所有&nbsp;©&nbsp;2023&nbsp;西拓行远科技有限公司，并保留所有权利&nbsp;
            <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color:#ffffff59;" target="_blank">京ICP备2023023448号</a>
          </span>
          <span class="text_13">使用条款</span>
          <div class="box_5 flex-col"></div>
          <span class="text_14">
            <a href="#/privacy-policy" target="_blank">隐私政策</a>
          </span>
        </div>
      </div>
    </div>
    <div class="section_5 flex-col">
      <div class="block_10 flex-col justify-end">
        <div class="block_15 flex-row">
          <img
            class="image_12"
            @click="jumpClick('app')"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng396101f58259bb965cea0d42c0dde37927c7d730cebec0b294dbe9d830da3cf1.png"
          />
          <span
            :class="{ text_15: true, 'selected-color': elId == 'app' }"
            @click="jumpClick('app')"
            >首页</span
          >
          <span class="text_17">
            <a
              @click="jumpClick('aboutUs')"
              :class="{ 'selected-color': elId == 'aboutUs' }"
              >关于我们</a
            >
          </span>
          <span class="text_16">
            <a
              @click="jumpClick('product')"
              :class="{ 'selected-color': elId == 'product' }"
              >产品</a
            >
          </span>
          <img
            class="label_3"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng4a12137981883ba158a366b9104a28a41438c8e7c8e589c440059aae048ebfb9.png"
          />
          <span class="text_18">4006780037</span>
        </div>
        <div class="image-wrapper_4 flex-row">
          <img
            :class="`image_13 ${
              { app: 'o', aboutUs: 't', product: 's' }[elId]
            }`"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng7a160c370a392a7b84e08a8a7d8c30ba64c04990a798dce3946405ef9c57f433.png"
          />
        </div>
      </div>
      <div class="text-wrapper_3">
        <span class="text_19">专业的</span>
        <span class="text_20">企业软件</span>
        <span class="text_21">服务商</span>
      </div>
      <div class="block_11 flex-col">
        <div class="text-wrapper_4">
          <span class="text_22">行稳致远</span>
          <span class="text_23">的长期主义企业价值观</span>
        </div>
      </div>
      <div class="box_13 flex-row justify-between">
        <img
          class="image_14"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng4b1779954e25de92672c8b49bb289f62011f212115a91f7c81d7855210b35b0d.png"
        />
        <div class="box_6 flex-col"></div>
      </div>
      <img
        class="image_15"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng97773bb6d91996183a3212687085cc7a51c278ac64a9d788a50761986f827e31.png"
      />
      <div class="block_13 flex-col">
        <div class="box_14 flex-row">
          <div class="image-text_5 flex-row justify-between">
            <img
              class="image_16"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngb649e3a6f0468c8be725d9eb165be3921444b9c86f181e80a536721e1b560f6b.png"
            />
            <div class="text-group_11 flex-col justify-between">
              <span class="text_28">政府</span>
              <span class="text_24">多地税收优惠对接</span>
            </div>
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_17"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnga1584ad9be227c47b63cfaad373e980cee4172f87a9abec0eb70c2c770d5fbbf.png"
            />
            <div class="text-group_12 flex-col justify-between">
              <span class="text_29">4000+</span>
              <span class="text_25">品牌合作加油站</span>
            </div>
          </div>
          <div class="image-text_7 flex-row justify-between">
            <img
              class="image_18"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng142a83b84d9051385f54555e386e43f7922cd75df495df41914f1ef1e043932a.png"
            />
            <div class="text-group_13 flex-col justify-between">
              <span class="text_30">200+</span>
              <span class="text_26">企业客户</span>
            </div>
          </div>
          <div class="image-text_8 flex-row justify-between">
            <img
              class="image_19"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng33f02e0cc2055aefe79b974b222020d78d76513995da69b8a83c19d7eb850f84.png"
            />
            <div class="text-group_14 flex-col justify-between">
              <span class="text_31">100000+</span>
              <span class="text_27">司机使用</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
      elId: "app",
    };
  },
  mounted() {
    setTimeout(() => {
      this.jumpClick("app");
    }, 200);
    const _this = this;
    // 监听滚动事件，根据滚动位置更新URL中的锚点值
    window.addEventListener("scroll", () => {
      const sections = ["app", "aboutUs", "product"];
      const scrollPosition = window.scrollY;

      for (const sectionId of sections) {
        const element = document.getElementById(sectionId);
        if (element) {
          const elementTop =
            element.getBoundingClientRect().top + scrollPosition;
          if (scrollPosition >= elementTop - 30) {
            // 更新URL中的锚点值
            _this.elId = sectionId;
            console.log(1);
          }
        }
      }
    });
  },
  methods: {
    jumpClick(id) {
      document.querySelector("#" + id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped lang="less" src="./assets/index.rem.less" />
