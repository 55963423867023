<template>
  <div class="privacy">
    <p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:Calibri"></span><span style="font-family:宋体">版本更新日期：</span><span style="font-family:Calibri">2023</span><span style="font-family:宋体">年</span><span style="font-family:Calibri">3</span><span style="font-family:宋体">月</span><span style="font-family:Calibri">7</span><span style="font-family:宋体">日</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">版本生效日期：</span>2023<span style="font-family:宋体">年</span><span style="font-family:Calibri">3</span><span style="font-family:宋体">月</span><span style="font-family:Calibri">7</span><span style="font-family:宋体">日</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">引言</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">您的信任对西拓司机宝非常重要，我们深知用户信息安全的重要性，我们致力于提升信息处理透明度，增强您对信息管理的便捷性，保障您的信息及通信安全。我们严格遵守法律法规，遵循以下隐私保护原则，为您提供更加安全、可靠的服务。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">本隐私政策适用于西拓司机宝的产品及或服务。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">如本隐私政策与特定服务的隐私政策有不一致之处，请以该特定隐私政策申明为准。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">我们团队一直对用户（或称</span><span style="font-family:Calibri">“</span><span style="font-family:宋体">您</span><span style="font-family:Calibri">”</span><span style="font-family:宋体">）的隐私非常重视。《西拓司机宝用户隐私保护政策》（以下简称</span><span style="font-family:Calibri">“</span><span style="font-family:宋体">本政策</span><span style="font-family:Calibri">”</span><span style="font-family:宋体">）主要向您说明</span><span style="font-family:Calibri">:</span><span style="font-family:宋体">我们收集哪些信息</span><span style="font-family:Calibri">·</span><span style="font-family:宋体">我们收集信息的用途 </span><span style="font-family:Calibri">· </span><span style="font-family:宋体">您所享有的权利 </span><span style="font-family:Calibri">· </span><span style="font-family:宋体">其他问题的处理。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">请您在使用西拓司机宝提供的产品及或服务之前务必仔细阅读本政策，详细了解我们对信息的收集、使用方式，以便您更好地了解我们的服务并作出适当的选择。若您在阅读本政策后使用我们的产品及服务（以下简称</span><span style="font-family:Calibri">“</span><span style="font-family:宋体">本服务</span><span style="font-family:Calibri">”</span><span style="font-family:宋体">），即表示您自愿认同我们在本政策中所述的全部内容。如您对本隐私政策内容有任何问题，请一定及时联系我们。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">本政策将帮助开发者及最终用户了解以下内容：</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">一、本隐私政策相关词语涵义</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">二、我们收集的信息范围</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">三、我们如何使用</span>Cookie<span style="font-family:宋体">和同类技术</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">四、我</span> <span style="font-family:宋体">们如何委托处理、共享、转让、公开披露用户信息</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">五、我们使用收集信息的目的</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">六、我们如何保护用户信息</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">七、您如何管理用户信息</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">八、</span></span></strong><strong><span style="color: rgb(79, 129, 189);font-size: 25px"><span style="font-family:宋体">我们如何处理未成年的个人信息</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">九、本隐私政策如何更新</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">十、如何联系我们</span></span></strong>
</p>
<p style="margin: 7px 0px; text-wrap: wrap;">
    <span style="font-family:宋体"><span style="font-size: 25px;"><strong>十一、用户注销</strong></span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">十二、其他</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">第一部分</span> <span style="font-family:宋体">本隐私政策相关词语涵义</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">1<span style="font-family:宋体">、用户：指成功注册并激活西拓司机宝账号的自然人、法人或其他组织和签署了本协议并使用西拓司机宝关联公司账号登录西拓司机宝平台的自然人、法人或其他组织。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">2<span style="font-family:宋体">、西拓司机宝：指北京西拓行远科技有限公司。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">3<span style="font-family:宋体">、西拓司机宝平台：指西拓司机宝及或其关联公司运营的，为您提供各项产品及或服务的网站、客户端、移动应用程序、微信公众号、微信小程序等渠道（包括西拓司机宝及关联公司根据未来技术发展和业务需要等未来技术发展出现设计的新的产品应用和运营形态）。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">产品及或服务：指西拓司机宝及或其关联公司通过西拓司机宝平台为您提供的各项产品及或服务。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">4<span style="font-family:宋体">、平台规则：指在西拓司机宝平台上已经发布的及未来根据需要不定期更新的或新发布的所有服务规则、公告及各项产品及或服务的流程说明、使用方法规则介绍等。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">5<span style="font-family:宋体">、个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">6<span style="font-family:宋体">、个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。人敏感信息包括</span></span><strong><span style="font-size: 16px"><span style="font-family:宋体">身份证</span></span></strong><span style="font-size: 16px"><span style="font-family:宋体">件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、</span>14<span style="font-family:宋体">岁以下（含）未成年的个人信息（我们将在本隐私政策中对所涉及的个人敏感信息以粗体和加</span><span style="font-family:Calibri">*</span><span style="font-family:宋体">号方式进行显著标识）。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">7<span style="font-family:宋体">、企业信息：指企业从事生产经营活动过程中形成的信息，以及西拓司机宝在为企业提供产品及或服务过程中产生的能够反映企业状况的信息。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">除另有约定外，本隐私政策所用定义与《西拓司机宝用户使用协议》中的定义具有相同的涵义。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">第二部分</span> <span style="font-family:宋体">我们收集的信息范围</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">1<span style="font-family:宋体">、您在使用本服务时主动提供的个人信息。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">2<span style="font-family:宋体">、您在注册并激活西拓司机宝账号、登录西拓司机宝平台并完成产品及或服务的认证过程中，您可能需要向我们提供相关用户信息，包括但不限于以下信息。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:Calibri">·</span><span style="font-family:宋体">基本信息（</span></span><span style="font-size: 18px;"><strong><span style="font-family: 宋体;">个人真实姓名、身份证号码、电话号码</span></strong></span><span style="font-size: 16px; font-family: 宋体;">）；</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:Calibri">·</span><span style="font-family:宋体">个人身份信息（</span></span><strong><span style="font-size: 18px;"><span style="font-family: 宋体;">身份证</span><span style="font-family: 宋体;">、</span><span style="font-family: 宋体;">行驶证</span><span style="font-family: 宋体;">等</span></span></strong><span style="font-size: 16px; font-family: 宋体;">）；</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:Calibri">·</span><span style="font-family:宋体">网络身份标识信息 （ </span><span style="font-size: 18px;"><strong><span style="font-family: 宋体;">系统账号、 </span><span style="font-family: Calibri;">IP </span><span style="font-family: 宋体;">地址、邮箱地址及与前述有关的密码</span></strong></span><span style="font-family:宋体">）；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:Calibri">·</span><span style="font-family:宋体">个人上网记录（<span style="font-family: 宋体; font-size: 18px;"><strong>网站浏览记录、软件使用记录、点击记</strong></span>录 ）；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:Calibri">·</span><span style="font-family:宋体">个人</span></span><strong><span style="font-size: 16px; font-family: 宋体;">位置信息</span></strong><span style="font-size: 16px; font-family: 宋体;">（</span><strong><span style="font-size: 18px;"><span style="font-family: 宋体;">行程信息、精准</span><span style="font-family: 宋体;">定位</span><span style="font-family: 宋体;">信息、经纬度等</span></span></strong><span style="font-size: 16px; font-family: 宋体;">）。</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">3<span style="font-family:宋体">、在登录西拓司机宝账号并完成产品及或服务的认证过程中，为了确保您所提供的信息和材料是真实、合法和有效的。我们还可能需要您主动上传主体资格证明文件等相关信息，包括但不限于</span></span><span style="font-size: 18px;"><strong><span style="font-family: 宋体;">企业名称、企业营业执照信息、法定代表人信息、企业联系人信息、企业联系方式（电话号码、电子邮箱）</span></strong></span><span style="font-size: 16px; font-family: 宋体;">。</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    4<span style="font-family: 宋体;">、 我们在您使用服务时获取的信息</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">我们的产品和服务包括一些基本业务功能和扩展业务功能。基本业务功能包含了实现物流信息交换及在线货物运输交易、运输过程中的能源消费所必须的功能、及保障服务正常运行和交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与</span>/<span style="font-family:宋体">或服务。这些基本业务功能必须收集的信息包括：</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>1<span style="font-family:宋体">）用户登录所必需的信息</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">a<span style="font-family:宋体">手机号码：当您成为西拓司机宝司机用户时，您需要向西拓司机宝提供您本人的手机号码，我们以此识别您在西拓司机宝平台的网络身份，并为您提供西拓司机宝平台服务。如果您拒绝提供手机号码，西拓司机宝将无法为您登录并提供服务。</span></span>
</p>
<p style="margin: 7px 0px; font-size: 16px; text-wrap: wrap;">
    <span style="font-size: 16px;"><span style="font-family: 宋体;">（</span><span style="font-family:Calibri">2</span><span style="font-family: 宋体;">）设备信息：我们会根据您在西拓司机宝平台上和产品及或服务使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、</span><span style="font-family: Calibri;">MAC</span><span style="font-family: 宋体;">地址、唯一设备标识符、应用列表信息等软硬件特征信息）、设备所在</span></span><strong style="font-family: Calibri;"><span style="font-size: 16px; font-family: 宋体;">位置</span></strong><span style="font-size: 16px; font-family: 宋体;">相关信息（</span><strong><span style="font-size: 18px;"><span style="font-family: Calibri;"><span style="font-family: 宋体;">例如</span>IP<span style="font-family: 宋体;">地址、 </span><span style="font-family: Calibri;">GPS </span></span><strong style="font-family: Calibri;"><span style="font-family: 宋体;">位置</span></strong><span style="font-family: Calibri;"><span style="font-family: 宋体;">以及能够提供相关信息的</span> Wi-Fi<span style="font-family: 宋体;">接入点、蓝牙和基站等传感器信息</span></span></span></strong><span style="font-size: 16px; font-family: 宋体;">）。</span>
</p>
<p style="margin: 7px 0px; font-size: 16px; text-wrap: wrap;">
    <span style="font-size: 16px;"><span style="font-family: 宋体;">（</span><span style="font-family:Calibri">3</span><span style="font-family: 宋体;">）日志信息：当您登录西拓司机宝平台、使用我们的产品及或服务时，我们会自动收集您对我们产品及或服务的详细使用情况，作为有关网络日志保存。例如您的搜索</span><strong><span style="font-size: 18px;"><span style="font-family: 宋体;">查询内容、</span><span style="font-family: Calibri;">IP</span><span style="font-family: 宋体;">地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等</span></span></strong><span style="font-family: 宋体;">。</span></span>
</p>
<p style="margin: 7px 0px; font-size: 16px; text-wrap: wrap;">
    <span style="font-size: 16px;"><span style="font-family: 宋体;">（</span><span style="font-family:Calibri">4</span><span style="font-family: 宋体;">）支付功能：您可以为其他人订购产品及或服务，您需要提供该实际订购人的前述用户信息。向我们提供该实际订购人的前述用户信息之前，您需确保您已经取得其授权同意。为确认交易状态及为您提供交易争议解决服务，我们会通过您基于交易所选择的交易对象、交易方式、第三方支付机构、物流公司等收集与交易进度相关的您的交易、支付，或将您的交易信息共享给上述服务提供者。</span></span>
</p>
<p style="margin: 7px 0px; font-size: 16px; text-wrap: wrap;">
    <span style="font-size: 16px;"><span style="font-family: 宋体;">（</span><span style="font-family:Calibri">5</span><span style="font-family: 宋体;">）当您开启设备</span></span><strong style="font-family: Calibri;"><span style="font-size: 16px; font-family: 宋体;">定位</span></strong><span style="font-size: 16px; font-family: 宋体;">功能并使用我们提供的相关服务时，我们会收集有关您的</span><strong style="font-family: Calibri;"><span style="font-size: 16px; font-family: 宋体;">位置信息</span></strong><span style="font-size: 18px;"><strong><span style="font-family: Calibri;"><span style="font-family: 宋体;">（包括</span>IP<span style="font-family: 宋体;">地址、设备</span><span style="font-family: Calibri;">MAC</span><span style="font-family: 宋体;">地址、</span><span style="font-family: Calibri;">GPS </span></span><strong style="font-family: Calibri;"><span style="font-family: 宋体;">位置信息</span></strong><span style="font-family: Calibri;"><span style="font-family: 宋体;">以及能够提供相关信息的</span> WI-FI<span style="font-family: 宋体;">接入点、蓝牙、基站等传感器信息、您提供的账号信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处</span></span><span style="font-family: 宋体;">地理</span><strong style="font-family: Calibri;"><span style="font-family: 宋体;">位置</span></strong><span style="font-family: 宋体;">的共享信息</span><span style="font-family: 宋体;">，您或其他人共享的照片包含的地理标记信息）</span></strong></span><span style="font-size: 16px; font-family: 宋体;">，您可以通过关闭</span><strong style="font-family: Calibri;"><span style="font-size: 16px; font-family: 宋体;">定位</span></strong><span style="font-size: 16px; font-family: 宋体;">功能，停止对您的地理位置信息的收集。</span>
</p>
<p style="margin: 7px 0px; font-size: 16px; text-wrap: wrap;">
    <span style="font-size: 16px;"><span style="font-family: 宋体;">（</span><span style="font-family:Calibri">6</span><span style="font-family: 宋体;">）客服及争议处理时：当您与我们联系或提出问题处理、争议纠纷处理申请时，为了保障您的账号及系统安全，我们需要您提供必要的用户信息以核验您的用户身份。为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信通话记录及相关内容（包括账号信息、</span></span><strong style="font-family: Calibri;"><span style="font-size: 16px"><span style="font-family:宋体">订单</span></span></strong><span style="font-family: Calibri; font-size: 16px;"><span style="font-family:宋体">信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息）。为了提供服务及改进服务质量的合理需要，我们还可能使用您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。</span></span>
</p>
<p style="margin: 7px 0px; font-size: 16px; text-wrap: wrap;">
    <span style="font-size: 16px;"><span style="font-family: 宋体;">（</span><span style="font-family:Calibri">7</span><span style="font-family: 宋体;">）使用第三方</span><span style="font-family: Calibri;">SDK</span><span style="font-family: 宋体;">收集、使用您的个人信息 、我们提供部分功能和</span><span style="font-family: Calibri;">/</span><span style="font-family: 宋体;">或服务时需要使用第三方 </span><span style="font-family: Calibri;">SDK </span><span style="font-family: 宋体;">（或 </span><span style="font-family: Calibri;">API </span><span style="font-family: 宋体;">）技术，这些第三方 </span><span style="font-family: Calibri;">&nbsp; &nbsp; &nbsp;SDK </span><span style="font-family: 宋体;">（或 </span><span style="font-family: Calibri;">API </span><span style="font-family: 宋体;">）可能会收集或使用您的部分个人信息 </span><span style="font-family: Calibri;">/ </span><span style="font-family: 宋体;">设备</span><span style="font-family: Calibri;">MAC</span><span style="font-family: 宋体;">地址 </span><span style="font-family: Calibri;">/ </span><span style="font-family: 宋体;">设备权限（具体可点击本链接查看详细情况）。我们会对相关 </span><span style="font-family: Calibri;">SDK </span><span style="font-family: 宋体;">（或 </span><span style="font-family: Calibri;">API </span><span style="font-family: 宋体;">）进行严格的安全检测，并采取技术手段，努力确保其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。接入的第三方</span><span style="font-family: Calibri;">SDK</span><span style="font-family: 宋体;">具体详见附件《接入第三方</span><span style="font-family: Calibri;">SDK</span><span style="font-family: 宋体;">目录》。</span></span>
</p>
<p style="margin: 7px 0px; font-size: 16px; text-wrap: wrap;">
    <span style="font-size: 16px;"><span style="font-family: 宋体;">（</span><span style="font-family:Calibri">8</span><span style="font-family: 宋体;">）我们可能从第三方获取您授权共享的账户信息</span><span style="font-family: Calibri;">(</span><span style="font-family: 宋体;">头像、昵称 </span><span style="font-family: Calibri;">) </span><span style="font-family: 宋体;">，并在您同意本隐私政策后将您的第三方账户与您的账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">您理解并同意，上述服务可能需要您在您的设备中开启您的</span></span><strong><span style="font-size: 16px"><span style="font-family:宋体">位置信息</span></span></strong><span style="font-size: 16px"><span style="font-family:宋体">（地理</span></span><strong><span style="font-size: 16px"><span style="font-family:宋体">位置</span></span></strong><span style="font-size: 16px"><span style="font-family:宋体">）、存储、摄像头（相机）、通讯录及或日历的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置或西拓司机宝平台上逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您务必注意，您开启任一权限即代表您授权并同意我们可以收集和使用相关用户信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关用户信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">* <span style="font-family:宋体">若你提供的信息中含有其他用户的用户信息，在向我们提供这些用户信息之前，您需确保您已经取得合法的授权。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">* <span style="font-family:宋体">若我们将信息用于本隐私政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的用户信息，均会事先获得您的同意。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">* <span style="font-family:宋体">若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集您的信息，并向您告知共享的信息内容，且涉及个人敏感信息、企业重要信息的在提供给我们使用前需经过您的明确确认，要求第三方对用户信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求该第三方承担相应法律责任；同时，我们的专业安全团队对用户信息会进行安全加固（<strong><span style="font-family: 宋体; font-size: 18px;">包括个人敏感信息、企业重要信息报备、加密存储、访问权限控制等</span></strong>）。我们会使用不低于我们对自身用户信息同等的保护手段与措施对间接获取的用户信息进行保护。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">6<span style="font-family:宋体">、 自启动和关联启动说明</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>1<span style="font-family:宋体">）为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本应用须使用</span><span style="font-family:Calibri">(</span><span style="font-family:宋体">自启动</span><span style="font-family:Calibri">)</span><span style="font-family:宋体">能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>2<span style="font-family:宋体">）当您打开</span><span style="font-family:Calibri">APP</span><span style="font-family:宋体">内容类推送消息，在征得您的明确同意后，会跳转</span><span style="font-family:Calibri">APP</span><span style="font-family:宋体">打开相关内容。在未征得您同意的情况下，则不会有关联启动</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>3<span style="font-family:宋体">）当您打开</span><span style="font-family:Calibri">APP</span><span style="font-family:宋体">内部下载的文件后，会关联启动第三方</span><span style="font-family:Calibri">APP</span><span style="font-family:宋体">。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">第三部分</span> <span style="font-family:宋体">我们如何使用</span>Cookie<span style="font-family:宋体">和同类技术</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">1<span style="font-family:宋体">、</span><span style="font-family:Calibri">Cookie</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">请您理解，我们的某些产品及或服务只能通过使用</span>Cookie<span style="font-family:宋体">才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对 </span><span style="font-family:Calibri">&nbsp; &nbsp; &nbsp;Cookie </span><span style="font-family:宋体">的接受程度或者拒绝我们的 </span><span style="font-family:Calibri">Cookie </span><span style="font-family:宋体">，但拒绝我们的 </span><span style="font-family:Calibri">Cookie</span><span style="font-family:宋体">在某些情况下可能会影响您安全访问网站和使用我们提供的产品及或服务。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">如果您的浏览器或浏览器附加服务允许，您可修改对</span>Cookie<span style="font-family:宋体">的接受程度或拒绝我们的</span><span style="font-family:Calibri">Cookie</span><span style="font-family:宋体">。但如果您这么做，在某些情况下可能会影响您安全访问西拓司机宝平台相关的网站，且可能需要在每一次访问西拓司机宝平台相关的网站时更改用户设置。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">2<span style="font-family:宋体">、网站信标和像素标签</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">除</span>Cookie<span style="font-family:宋体">外，我们还会在西拓司机宝平台相关的网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至西拓司机宝平台相关的网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品及或服务偏好以便于我们主动改善用户体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">3<span style="font-family:宋体">、</span><span style="font-family:Calibri">Do Not Track </span><span style="font-family:宋体">（请勿追踪）</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">很多网络浏览器均设有</span>Do Not Track<span style="font-family:宋体">功能，该功能可向网站发布 </span><span style="font-family:Calibri">Do Not Track</span><span style="font-family:宋体">请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 </span><span style="font-family:Calibri">Do Not Track</span><span style="font-family:宋体">，那么西拓司机宝平台相关的所有网站都会尊重您的选择。您还可以使用您的浏览器设置来删除或阻止您计算机上的</span><span style="font-family:Calibri">Cookie </span><span style="font-family:宋体">。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">第四部分</span> <span style="font-family:宋体">我们如何委托处理、共享、转让、公开披露用户信息</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong>1<span style="font-family:宋体">、 委托处理</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">请您理解，我们的某些产品及或服务只能通过使用</span>Cookie<span style="font-family:宋体">才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对</span><span style="font-family:Calibri">Cookie </span><span style="font-family:宋体">的接受程度或者拒绝我们的 </span><span style="font-family:Calibri">Cookie </span><span style="font-family:宋体">，但拒绝我们的 </span><span style="font-family:Calibri">Cookie</span><span style="font-family:宋体">在某些情况下可能会影响您安全访问网站和使用我们提供的产品及或服务。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">对我们委托处理用户信息的公司、组织和个人，我们会与其签署严格的保密协议，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong>2 <span style="font-family:宋体">、 共享</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">我们不会与我们服务提供商以外的公司、组织和个人共享用户信息，但以下情况除外：</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>1<span style="font-family:宋体">）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的信息。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>2<span style="font-family:宋体">）在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按照行政、司法机关和监管机构依法提出的要求，依法向该等提出房提供您的相关信息。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>3<span style="font-family:宋体">）在您主动选择情况下共享：您通过西拓司机宝平台订购我们的产品及或服务，我们会根据您的选择，将您的</span></strong><strong><span style="font-family:宋体">订单</span></strong><strong><span style="font-family:宋体">信息中与交易有关的必要信息共享给相关产品及或服务的提供者，以实现您的交易及售后服务需求。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>4<span style="font-family:宋体">）关联公司之间共享：因您可以使用西拓司机宝账号登录西拓司机宝平台、使用我们的产品及或服务，为便于我们基于统一的账号共同向您提供产品及或服务，保护我们或其他用户、公众的人身财产安全免遭侵害，您的信息可能会在我们之前共享。我们只会共享必要的用户信息（如为便于您使用西拓司机宝账号使用西拓司机宝关联公司的产品及或服务，西拓司机宝会向关联公司共享您必要的账号信息），如果我们共享您的个人敏感信息、企业重要信息或关联公司改变用户信息的使用及处理目的，将再次征求您的授权同意。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>5<span style="font-family:宋体">）与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些产品及或服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，并且只会共享提供产品及或服务所必要的用户信息。我们的合作伙伴无权将共享的用户信息用于与产品及或服务无关的其他用途。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>6<span style="font-family:宋体">）对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的数据保护协议，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>7<span style="font-family:宋体">）我们会对授权合作伙伴获取有关信息的应用程序接口（</span><span style="font-family:Calibri">API</span><span style="font-family:宋体">）、软件工具开发包（</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。接入的第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">具体详见附件《接入第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">目录》</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong>3<span style="font-family:宋体">、 转让</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">我们不会将您的信息转让给任何公司、组织和个人，但以下情况除外：</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>1<span style="font-family:宋体">）在您点击同意本隐私政策并使用西拓司机宝关联公司账号登录西拓司机宝平台时，您自愿并同意西拓司机宝关联公司将您的信息转让给西拓司机宝，以便我们对用户的账号和其他信息进行统一管理、加强用户信息安全保护并为用户提供更加优质、高效、全面的产品及或服务。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>2<span style="font-family:宋体">）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的信息。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>3<span style="font-family:宋体">）在我们产品及或服务的提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，我们会要求新的持有您信息的公司、组织和个人继续受本隐私政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong>4<span style="font-family:宋体">、 公开披露</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">我们仅会在以下情况下，公开披露用户信息：</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>1<span style="font-family:宋体">） 获得您明确同意或基于您的主动选择，我们可能会公开披露您的信息；</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>2<span style="font-family:宋体">）如果我们确定您出现违反法律法规或严重违反本隐私政策、平台规则的情况，或为保护我们及其他用户、公众的人身财产安全免遭侵害，我们在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，可能会公开披露您的信息。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">（</span>3<span style="font-family:宋体">）委托处理、共享、转让、公开披露用户信息时事先征得授权同意的例外，以下情形中，委托处理、共享、转让、公开披露用户信息无需事先征得用户的授权同意：</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong>a <span style="font-family:宋体">与国家安全、国防安全有关的；</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong>b <span style="font-family:宋体">与公共安全、公共卫生、重大公共利益有关的；</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong>c <span style="font-family:宋体">与犯罪侦查、起诉、审判和判决执行等有关的；</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong>d <span style="font-family:宋体">出于维护用户或其他个人的生命、财产企业财产等重大合法权益但又很难得到用户亲自同意的；</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong>e <span style="font-family:宋体">用户自行向社会公众公开的信息；</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong>f&nbsp; <span style="font-family:宋体">从合法公开披露的信息中收集用户信息的，如合法的新闻报道、政府信息公开等渠道；</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong>g <span style="font-family:宋体">《个人信息保护法》规定的其他情形。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-family:宋体">根据法律规定，共享、转让经去标识化处理的用户信息，且确保数据接收方无法复原并重新识别该用户的，不属于用户信息的对外委托处理、共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">第五部分</span> <span style="font-family:宋体">我们使用收集信息的目的</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">我们严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途。若我们超出以下用途使用您的信息，我们将再次向您进行说明，并征得您的同意。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">1 <span style="font-family:宋体">、向您提供服务及改善服务。为了让您有更好的体验、改善我们的服务或经您同意的其他用途，在符合相关法律法规的前提下，我们可能会将您的信息用于产品改进，如统计我们产品的用户数量、分析产品的使用状况、</span><span style="font-family:Calibri">APP</span><span style="font-family:宋体">升级改版、排查崩溃原因、减少崩溃情况、改进产品 </span><span style="font-family:Calibri">BUG</span><span style="font-family:宋体">、优化产品稳定性及功能性、打击违法犯罪及防治用户个人信息泄露等。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">2&nbsp;<span style="font-family:宋体">、产品开发和服务优化。例如，当我们的系统发生故障时，我们会记录和分析系统故障时产生的信息，优化我们的服务。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">3&nbsp;<span style="font-family:宋体">、安全保障。为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关信息：</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>1 <span style="font-family:宋体">）例如，我们会将您的信息用于身份验证、安全防范、反诈骗监测、存档备份、客户的安全服务等用途；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>2 <span style="font-family:宋体">）例如，您使用应用程序的频率、故障信息、总体使用情况、性能数据以及应用程序的来源。我们不会将我们存储在分析软件中的信息与您在应用程序中提供的个人身份信息相结合。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">4&nbsp;<span style="font-family:宋体">、管理软件。例如，进行软件认证、软件升级等。</span></span>
</p>
<p style="margin: 7px 0px; font-size: 16px; text-wrap: wrap;">
    <span style="font-size: 16px;"><font face="Calibri">5</font><span style="font-family: 宋体;">、邀请您参与有关本服务的调查</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">为了让您有更好的体验、改善我们的服务或经您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某些服务所收集的信息用于我们的其他服务。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关信息，例如，您使用应用程序的频率、故障信息、总体使用情况、性能数据以及应用程序的来源。我们不会将我们存储在分析软件中的信息与您在应用程序中提供的个人身份信息相结合。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">第六部分</span> <span style="font-family:宋体">我们如何保护用户信息安全</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">1 <span style="font-family:宋体">、我们已采取符合业界标准、合理可行的安全防护措施保护用户信息安全，防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏</span><span style="font-family:Calibri">Hyper Text Transfer Protocol over Secure Socket Layer </span><span style="font-family:宋体">）协议安全浏览方式；我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">2 <span style="font-family:宋体">、我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升西拓司机宝平台及产品及或服务的安全性。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">3 <span style="font-family:宋体">、我们会采取合理可行的措施，尽力避免收集无关的用户信息。我们只会在达成本隐私政策所述目的所需的期限内保留用户信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准包括：</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>1<span style="font-family:宋体">）完成与您相关的产品及服务使用目的、维护相应业务记录、应对您可能的查询或投诉；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>2<span style="font-family:宋体">）保证我们为您提供产品及或服务的安全和质量；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>3<span style="font-family:宋体">）您是否同意更长的留存期间；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>4<span style="font-family:宋体">）是否存在保留期限的其他特别约定。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">在用户信息超出保留期间后，我们会根据适用法律的要求删除用户信息，或使其匿名化处理。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">4 <span style="font-family:宋体">、互联网并非绝对安全的环境，我们强烈建议您不要使用非我们推荐的通信方式发送用户信息。您可以通过西拓司机宝平台建立联系和相互分享。当您通过西拓司机宝平台创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您使用的产品及或服务内容、联络方式、交流信息或分享内容等相关信息的第三方。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">请注意，您在西拓司机宝平台上自愿共享甚至公开分享的信息，可能会涉及您或第三方的信息甚至个人敏感信息、企业重要信息。请您更加谨慎地考虑，是否在西拓司机宝平台上共享甚至公开分享相关信息。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">5 <span style="font-family:宋体">、请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">6 <span style="font-family:宋体">、我们将不定期更新并公开安全风险、用户信息安全影响评估报告等有关内容，您可通过我们公告方式获得。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">7 <span style="font-family:宋体">、在不幸发生用户信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户时，我们会采取合理、有效的方式发布公告。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">第七部分</span> <span style="font-family:宋体">您如何管理用户信息</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">您可以通过以下方式访问及管理您的信息：</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">1 <span style="font-family:宋体">、访问您的信息</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">您有权访问您的信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的信息：</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">账号信息</span><span style="font-family:Calibri">—— </span><span style="font-family:宋体">如果您希望访问或编辑您的账号中的基本用户信息、更改您的账号密码、添加安全信息或关闭您的账号等，您可以通过登录西拓司机宝个人用户中心进行账号更改。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">如果您无法通过上述路径访问该等用户信息，您可以随时通过本隐私政策中披露的联系方式与我们取得联系。我们将在</span>15<span style="font-family:宋体">天内回复您的访问请求。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">对于您在使用我们的产品及或服务过程中产生的其他信息，我们将根据本条</span><span style="font-family:Calibri">“</span><span style="font-family:宋体">（八）响应您的上述请求 </span><span style="font-family:Calibri">” </span><span style="font-family:宋体">中的相关安排向您提供。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">2 <span style="font-family:宋体">、更正或补充您的信息</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">当您发现我们处理的关于您的信息有错误时，您有权要求我们做出更正或补充。您可以通过</span><span style="font-family:Calibri">“ 1 ” </span><span style="font-family:宋体">访问您的信息</span><span style="font-family:Calibri">”</span><span style="font-family:宋体">中列明的方式提出更正或补充申请。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">3 <span style="font-family:宋体">、删除您的信息</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">您可以通过</span><span style="font-family:Calibri">“ 1 ” </span><span style="font-family:宋体">访问您的信息</span><span style="font-family:Calibri">”</span><span style="font-family:宋体">中列明的方式删除您的部分信息。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">在以下情形中，您可以向我们提出删除信息的请求：</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>1<span style="font-family:宋体">） 如果我们处理信息的行为违反法律法规；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>2<span style="font-family:宋体">） 如果我们收集、使用您的信息，却未征得您的明确同意；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>3<span style="font-family:宋体">） 如果我们处理信息的行为严重违反了与您的约定；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>4<span style="font-family:宋体">） 如果您不再使用我们的产品及或服务，或您主动注销了账号；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">4 <span style="font-family:宋体">、 如果我们永久不再为您提供产品及或服务。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的信息的第三方，要求其及时删除，除非法律法规另有规定，或这些第三方获得您的独立授权。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">5 <span style="font-family:宋体">、 改变您授权同意的范围</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">我们产品及或服务的每个业务功能可能需要一些基本用户信息才能得以完成（见本隐私政策</span><span style="font-family:Calibri">“</span><span style="font-family:宋体">第二部分 </span><span style="font-family:Calibri">”</span><span style="font-family:宋体">）。除此之外，对于额外用户信息的收集和使用，您可以通过登录账号、通过与我们联系给予或收回您的授权同意。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">当您收回同意后，我们将不再处理相应的用户信息。但您收回同意的决定，不会影响此前基于您的授权而开展的用户信息处理及效力。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">6 <span style="font-family:宋体">、 获取用户信息副本</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">您有权获取您的信息副本，您可以通过以下方式自行操作：我们在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的信息副本传输给您指定的第三方，但是不得侵害我们的权益。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">7 <span style="font-family:宋体">、 约束信息系统自动决策</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">在我们产品及或服务的某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害我们商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">8 <span style="font-family:宋体">、 响应您的上述请求</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">我们将在</span>15<span style="font-family:宋体">天内做出答复。如您不满意，还可以通过联系我们发起投诉。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。在以下情形中，按照法律法规要求，我们将无法响应您的请求：</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>1<span style="font-family:宋体">） 与国家安全、国防安全有关的；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>2<span style="font-family:宋体">） 与公共安全、公共卫生、重大公共利益有关的；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>3<span style="font-family:宋体">） 与犯罪侦查、起诉、审判和执行判决等有关的；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>4<span style="font-family:宋体">） 有充分证据表明用户存在主观恶意或滥用权利的；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>5<span style="font-family:宋体">） 响应用户的请求将导致用户或其他个人、组织、公司的合法权益受到严重损害的；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">（</span>6<span style="font-family:宋体">） 涉及商业秘密的。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="color: rgb(79, 129, 189);font-size: 25px"><span style="font-family:宋体">第八部分</span> <span style="font-family:宋体">我们如何处理未成年的个人信息</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="color: rgb(79, 129, 189)">1<span style="font-family:宋体">、如果没有父母或监护人的同意，未成年不得创建自己的西拓司机宝账号。如您为未成年的，建议您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的产品及或服务或向我们提供信息。</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="color: rgb(79, 129, 189)">2<span style="font-family:宋体">、对于经父母或监护人同意使用我们的产品及或服务而收集未成年个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年所必要的情况下使用、共享、转让或披露此信息。</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="color: rgb(79, 129, 189)">3<span style="font-family:宋体">、我们将不满 </span><span style="font-family:Calibri">14</span><span style="font-family:宋体">周岁的任何人均视为未成年，如果当地法律和习俗对未成年的定义不同，则适用当地法律和习俗的定义。</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">第九部分</span> <span style="font-family:宋体">本隐私政策如何更新</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">我们的隐私政策可能变更。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在西拓司机宝平台上发布对隐私政策所做的任何变更。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">对于重大变更，我们还会提供更为显著的通知（包括我们会通过我们公示的方式进行意见征集、公告通知甚至向您提供弹窗提示）。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">本隐私政策所指的重大变更包括但不限于：</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">1&nbsp; <span style="font-family:宋体">、我们的产品及或服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">2 <span style="font-family:宋体">、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">3 <span style="font-family:宋体">、用户信息共享、转让或公开披露的主要对象发生变化；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">4 <span style="font-family:宋体">、您参与用户信息处理方面的权利及其行使方式发生重大变化；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">5 <span style="font-family:宋体">、我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化；</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">6<span style="font-family:宋体">、 用户信息安全影响评估报告表明存在高风险。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">第十部分</span> <span style="font-family:宋体">如何联系我们</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">有关本隐私政策或我们的隐私措施相关问题可通过如下方式联系我们，我们将在</span>15<span style="font-family:宋体">天内回复您的请求：</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">联系电话：</span>4006780037</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">联系地址：北京市通州区新华北路</span>55<span style="font-family:宋体">号</span><span style="font-family:Calibri">2</span><span style="font-family:宋体">幢四层</span><span style="font-family:Calibri">8-35</span><span style="font-family:宋体">室</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">如果您对我们的回复不满意，特别是您认为我们的用户信息处理行为损害了您的合法权益，您还可以通过向西拓司机宝住所所在地有管辖权的法院提起诉讼来寻求解决方案。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体"></span></span>
</p>
<p style="margin-top: 7px; margin-bottom: 7px; text-wrap: wrap;">
    <strong style=""><span style="font-size: 25px;"><span style="font-family: 宋体;">第十一部分</span><span style="font-family:Calibri">&nbsp;</span><span style="font-family:宋体">用户注销</span></span></strong>
</p>
<p style="margin-top: 7px; margin-bottom: 7px; text-wrap: wrap; font-family: Calibri;">
    <span style="font-family: 宋体;"></span>
</p>
<p>
    您随时可注销此前注册的账户，您可以通联系西拓客服人员（<span style="font-family: Calibri; text-wrap: wrap;">4006780037</span>）提出注销请求，我们将在15日内处理完毕。
</p>
<p>
    在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息或对其进行匿名化处理，法律法规另有规定的除外。
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <strong><span style="font-size: 25px"><span style="font-family:宋体">第十二部分</span> <span style="font-family:宋体">其他</span></span></strong>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">1 <span style="font-family:宋体">、如本政策与我国相关法律法规的规定相悖，以法律法规的规定为准。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">2 <span style="font-family:宋体">、西拓司机宝有权根据我国相关法律法规的规定对本政策进行调整。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">《接入第三方</span>SDK<span style="font-family:宋体">目录》</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">SDK<span style="font-family:宋体">名称：高德开放平台</span></span><strong><span style="font-size: 16px"><span style="font-family:宋体">定位</span></span></strong><span style="font-size: 16px">SDK</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">第三方名称：高德软件有限公司</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">使用目的：为了向用户提供</span></span><strong><span style="font-size: 16px"><span style="font-family:宋体">定位</span></span></strong><span style="font-size: 16px"><span style="font-family:宋体">服务</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">收集个人信息：</span></span><strong><span style="font-size: 16px"><span style="font-family:宋体">位置信息</span></span></strong><span style="font-size: 16px"><span style="font-family:宋体">、设备信息（包括</span>IMEI<span style="font-family:宋体">、</span><span style="font-family:Calibri">IDFA</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">Android &nbsp; &nbsp; &nbsp;ID</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">MEID</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">MAC</span><span style="font-family:宋体">地址、</span><span style="font-family:Calibri">OAID</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">IMSI</span><span style="font-family:宋体">、硬件序列号）。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">隐私政策链接：</span> https://lbs.amap.com/pages/privacy</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">SDK<span style="font-family:宋体">名称：高德开放平台搜索</span><span style="font-family:Calibri">SDK</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">第三方名称：高德软件有限公司</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">使用目的：为了实现向用户提供搜索、信息展示服务</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">收集个人信息：</span></span><strong><span style="font-size: 16px"><span style="font-family:宋体">位置信息</span></span></strong><span style="font-size: 16px"><span style="font-family:宋体">、设备信息（包括</span>IMEI<span style="font-family:宋体">、</span><span style="font-family:Calibri">IDFA</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">Android &nbsp; &nbsp; &nbsp;ID</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">MEID</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">MAC</span><span style="font-family:宋体">地址、</span><span style="font-family:Calibri">OAID</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">IMSI</span><span style="font-family:宋体">、硬件序列号）。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">隐私政策链接：</span> https://lbs.amap.com/pages/privacy</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">SDK<span style="font-family:宋体">名称：高德开放平台导航</span><span style="font-family:Calibri">SDK</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">第三方名称：高德软件有限公司</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">使用目的：为了实现向用户提供基于用户</span></span><strong><span style="font-size: 16px"><span style="font-family:宋体">位置</span></span></strong><span style="font-size: 16px"><span style="font-family:宋体">的导航服务</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">收集个人信息：</span></span><strong><span style="font-size: 16px"><span style="font-family:宋体">位置信息</span></span></strong><span style="font-size: 16px"><span style="font-family:宋体">、设备信息（包括</span>IMEI<span style="font-family:宋体">、</span><span style="font-family:Calibri">IDFA</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">Android ID</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">MEID</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">MAC</span><span style="font-family:宋体">地址、</span><span style="font-family:Calibri">OAID</span><span style="font-family:宋体">、</span><span style="font-family:Calibri">IMSI</span><span style="font-family:宋体">、硬件序列号）。</span></span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px"><span style="font-family:宋体">隐私政策链接：</span> https://lbs.amap.com/pages/privacy</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 7px 0;font-family: Calibri;font-size: 16px;text-wrap: wrap">
    <span style="font-size: 16px">&nbsp;</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;text-wrap: wrap">
    <span style="font-size: 14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped>
.privacy {
  width: 80%;
  margin: 0 auto;
  font-size: 14px;
}
</style>
