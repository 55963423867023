<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="text-wrapper_1">
        <span class="text_1">专业的</span>
        <span class="text_2">企业软件</span>
        <span class="text_3">服务商</span>
      </div>
      <div class="block_1 flex-col">
        <div class="text-wrapper_2">
          <span class="text_4">行稳致远</span>
          <span class="text_5">的长期主义企业价值观</span>
        </div>
      </div>
      <div class="block_2 flex-col"></div>
      <img
        class="image_1"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng26de39f69fa2c62140b9f4861f573644173951e0a9ef0dca17fd06b1e3bcaeb9.png"
      />
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngbf7b156d0641cea53f46850469d0822e53d314e15c51f5471d33541425669bc9.png"
      />
      <div class="block_3 flex-row">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngae90956fa21c4e259985e2b387f9a1ee4fda87326377bbae44ccc323a26e3603.png"
        />
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng593821cd94121e6e16e846226c84d5a5891c51a91b5cf454b3232b78a3c32291.png"
        />
        <span class="text_6">4006780037</span>
      </div>
    </div>
    <div class="group_21 flex-col">
      <div class="section_17 flex-row justify-between">
        <div class="image-text_33 flex-col justify-between">
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng254e00a6e39da0499d3933b3673f51809bb9f6698320b302db83420195e69e3f.png"
          />
          <div class="text-group_40 flex-col justify-between">
            <span class="text_7">政府</span>
            <span class="text_8">多地税收优惠对接</span>
          </div>
        </div>
        <div class="image-text_34 flex-col justify-between">
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngc20b6a09e1e13e7f79d1323b10edf483c5457f2d8d8a141c03092910ed692c83.png"
          />
          <div class="text-group_41 flex-col justify-between">
            <span class="text_9">4000+</span>
            <span class="text_10">品牌合作加油站</span>
          </div>
        </div>
      </div>
      <div class="section_18 flex-row justify-between">
        <div class="image-text_35 flex-col justify-between">
          <img
            class="image_6"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng16a6aeee7b6c54d4714a51782f30b92181d756623238a122a2db89786e594364.png"
          />
          <div class="text-group_42 flex-col justify-between">
            <span class="text_11">200+</span>
            <span class="text_12">企业客户</span>
          </div>
        </div>
        <div class="image-text_36 flex-col justify-between">
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng0256f3ffde92d10aa22595126fd3af8f4f4d91de87c4505337206d77b4508ff8.png"
          />
          <div class="text-group_43 flex-col justify-between">
            <span class="text_13">100000+</span>
            <span class="text_14">司机使用</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box_10 flex-col">
      <div class="section_3 flex-col">
        <div class="block_4 flex-col"></div>
        <div class="text-group_44 flex-col">
          <span class="text_15">十年沉淀领先产品与优秀口碑</span>
          <span class="text_16">
            “西拓行远科技有限公司”成立于2014年，总部位于北京，致力于为不同行业的企业客户提供解决方案级别的车用能源SaaS工具及运营服务，帮助客户实现“流程步步清楚、管理心中有数”的管理数字化目标。
          </span>
          <span class="text_17">
            公司核心成员来自互联网、出行、能源等行业头部企业高管，拥有领先的产品技术及扎实的运营服务能力，曾为京东、顺丰、中通、极兔、满帮、国家电网、南方电网等知名客户提供一站式服务。
          </span>
        </div>
        <img
          class="image_8"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng51c4c812f01dfad3f1f8d4842edb4f4c2364b98e53934a8f0627fc16e6ff8ec2.png"
        />
        <div class="image-wrapper_22 flex-row">
          <img
            class="image_18"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngcaff2fa5b0a1068da7a4300e668752c7b8b3a3c2e143612ba403d85acd819d61.png"
          />
          <img
            class="image_19"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngbfc24b26331e8f3fd580b6a9759e4d937a32b6423be4f8f4630ddfcb24d71cce.png"
          />
          <img
            class="image_20"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngb815b8821ad3bf3a89d8130584768a7bb5a374a3174415bd7136e7a07e876521.png"
          />
        </div>
        <div class="image-wrapper_23 flex-row">
          <img
            class="image_21"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng0e7ef44a8dcb4defff198b9fa83b92828f047fed833fbbb00ea4d899df437c2e.png"
          />
          <img
            class="image_22"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngc4636a882c85582013de031779a59064096bd60aa390908c44f855e4ee34bf5e.png"
          />
          <img
            class="image_23"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng5816906ecad115ec4ab3d18e0ff5048f08d1c2555706cb0eadc401a1d050ae7e.png"
          />
          <img
            class="image_24"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng1281a4ea6428c2ae0ea15432bfd9fb1454fe8eb09d4b11e6d424d5598d20b9a0.png"
          />
        </div>
      </div>
    </div>
    <div class="group_22 flex-col">
      <span class="text_18">产品只是基础、服务体现差异</span>
      <img
        class="image_16"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngdd144b2aec6abc1bfcec7167664d9520ef4948948369c90790df12d7727565b9.png"
      />
      <div class="group_3 flex-col">
        <div class="box_23 flex-row justify-between">
          <div class="section_5 flex-col"></div>
          <div class="section_6 flex-col"></div>
          <div class="section_7 flex-col"></div>
        </div>
        <img
          class="group_5"
          referrerpolicy="no-referrer"
          src="./assets/img/ae08a70df9374d6583ee65a373bc09b1_mergeImage.png"
        />
      </div>
      <div class="group_6 flex-col"><div class="block_5 flex-col"></div></div>
    </div>
    <div class="section_8 flex-col">
      <img
        class="image_17"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng65430459f167db955baa53c01e2f82b6332edb37c8844aa789eb96b01eecd327.png"
      />
      <div class="text-wrapper_37 flex-row justify-between">
        <span class="text_19">联系我们</span>
        <span class="text_20">关注我们</span>
      </div>
      <div class="group_23 flex-row justify-between">
        <div class="text-wrapper_38 flex-col">
          <span class="text_21">公司邮箱：</span>
          <span class="text_22">wuxiaoli&#64;&nbsp;westtxy.com</span>
          <span class="text_23">联系电话：</span>
          <span class="text_24">4006780037</span>
        </div>
        <div class="group_7 flex-col"><div class="box_2 flex-col"></div></div>
      </div>
      <span class="text_25">公司地址</span>
      <span class="text_26">北京市通州区新华北路55号2幢四层8-35室</span>
    </div>
    <div class="section_9 flex-col">
      <div class="box_24 flex-row">
        <span class="text_27">使用条款</span>
        <div class="block_7 flex-col"></div>
        <span class="text_28"
          ><a href="#/privacy-policy" target="_blank">隐私政策</a></span
        >
      </div>
      <span class="text_29">
        版权所有&nbsp;©&nbsp;2023&nbsp;西拓行远科技有限公司，并保留所有权利&nbsp;京ICP备2023023448号
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="less" src="./assets/index.rem.less" />
